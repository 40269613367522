import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {

  connect () {
    const imagePreview = $('.image-preview')

    imagePreview.mouseenter(function () {
      const imageName = $(this).data('image')
      const imageTag = `<div class="image" style="position:absolute;"><img src="${imageName}" alt="🚫" height="400" /></div>`
      $(this).parent('td').append(imageTag)
    })

    imagePreview.mouseleave(function () {
      $(this).parent('td').children('div.image').remove()
    })
  }
}
