import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  constructor (context) {
    super(context)
    this.table = null
  }

  connect () {
    this.table = $('.table').DataTable({
      language: {
        url: CookieLanguageHelper.getTableTranslationFile()
      },
      paging: true,
      searching: false,
      sort: true,
      'bInfo': false,
      'aLengthMenu': [[25, 50, -1], [25, 50, 'Wszystkie']],
      'pageLength': 25
    })
  }

  disconnect () {
    super.disconnect()
    this.table.destroy()
  }
}
